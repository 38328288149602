@import '../partials';

.blockWysiwyg {
  @include section-padding;
  padding-top: 0;
  padding-bottom: 0;

  font-size: rem(18);
  line-height: rem(32);

  &__boundary {
    @include content-contaner;

    .layout-full-width & {
      max-width: 900px;
    }
  }

  a {
    color: $blue;
    transition: color $fast-duration ease;

    &:hover {
      color: $sky;
    }
  }
}